import { useTranslation } from "react-i18next";
import Line from "../../components/ui/line";
import BorderView from "../../components/views/BorderView";
import { VerticalView } from "../../components/views/ContentViews";
import ImageCover from "../../components/views/ImageCover";

const ACS = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"ACS"} src={"/covers/health_acs.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("health.acs.title")}
            <Line color="var(--blue4)" />
          </h1>
          <text>
            <h3>
              <i>
                <b> {t("health.acs.ambulatoryCare")}</b>
              </i>{" "}
              &nbsp; {t("health.acs.refersTo")}
            </h3>
            <h3>{t("health.acs.paragraphs.0")}</h3>
            <h3>{t("health.acs.paragraphs.1")}</h3>
            <h3>{t("health.acs.paragraphs.2")}</h3>
            <h3>{t("health.acs.paragraphs.3")}</h3>
          </text>
        </VerticalView>
      </BorderView>
    </div>
  );
};

export default ACS;
