import { useTranslation } from "react-i18next";
import Line from "../components/ui/line";
import ToggleItem from "../components/ui/toggleItem";
import BorderView from "../components/views/BorderView";
import { VerticalView } from "../components/views/ContentViews";
import ImageCover from "../components/views/ImageCover";

const Training = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"CSP"} src={"/covers/training.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("training.title")}
            <Line color="var(--blue4)" />
          </h1>
          <text>
            <h2> {t("training.sections.0.title")}</h2>
            <ul>
              <li>
                {t("training.sections.0.content.0")}
                <br />
                {t("training.sections.0.content.1")}
              </li>
            </ul>

            <h2>{t("training.sections.1.title")}</h2>
            <ul>
              <li>{t("training.sections.1.content.0")}</li>
            </ul>

            <h2>{t("training.sections.2.title")}</h2>
            <h3>
              <a href="https://www.dotmedica.com/page/hse-first-aid-at-work-course">
                https://www.dotmedica.com/page/hse-first-aid-at-work-course
              </a>
            </h3>
            <h2>{t("training.sections.3.title")}</h2>

            <h3>
              {t("training.sections.3.content.0")}
              <br />
              <br />
              <a href="https://emergencycare.hsi.com/compliance-and-qa">
                https://emergencycare.hsi.com/compliance-and-qa
              </a>
              <br />
              <br />
              {t("training.sections.3.content.1")}
            </h3>
            <h2>{t("training.availableCourses")}</h2>
            <ol>
              <li>
                <ToggleItem title={t("training.courses.0.title")}>
                  {t("training.courses.0.description")}
                </ToggleItem>
              </li>
              <li>
                <ToggleItem title={t("training.courses.1.title")}>
                  {t("training.courses.1.description")}
                </ToggleItem>
              </li>
              <li>
                <ToggleItem title={t("training.courses.2.title")}>
                  {t("training.courses.2.description")}
                </ToggleItem>
              </li>
              <li>
                <ToggleItem title={t("training.courses.3.title")}>
                  {t("training.courses.3.description")}
                </ToggleItem>
              </li>
              <li>
                <ToggleItem title={t("training.courses.4.title")}>
                  {t("training.courses.4.description")}
                </ToggleItem>
              </li>
              <li>
                <ToggleItem title={t("training.courses.5.title")}>
                  {t("training.courses.5.description")}
                </ToggleItem>
              </li>
            </ol>
          </text>
        </VerticalView>
      </BorderView>
    </div>
  );
};

export default Training;
