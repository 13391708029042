import { getUrl } from "../config/url";
import { myInsuranceEndPoint } from "../config/endpoints";
import { IGuest } from "../models/guest";


export const getInsurance = async (uuid: string):Promise<IGuest> => {

  var requestOptions = {
    method: 'GET',
  };
  //to avoid cache issue I added timeStamp 
    const response = await fetch(getUrl(`${myInsuranceEndPoint}/${uuid}?timeStamp=${ new Date().getTime().toString()}`), requestOptions)
    const obj = await response.json();
    const data: IGuest[] = await obj.data;

  return data[0];

};
