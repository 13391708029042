import { useTranslation } from "react-i18next";
import Line from "../../components/ui/line";
import BorderView from "../../components/views/BorderView";
import { VerticalView } from "../../components/views/ContentViews";
import ImageCover from "../../components/views/ImageCover";

const DCI = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"DES"} src={"/covers/health_dci.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("health.dci.title")}
            <Line color="var(--blue4)" />
          </h1>
          <h2>{t("health.dci.decompressionIllness.title")}</h2>
          <ul>
            <li>{t("health.dci.decompressionIllness.listItems.0")}</li>
            <li>{t("health.dci.decompressionIllness.listItems.1")}</li>
          </ul>

          <h2>{t("health.dci.decompressionSickness.title")}</h2>
          <h3>{t("health.dci.decompressionSickness.description")}</h3>

          <h2>{t("health.dci.arterialGasEmbolism.title")}</h2>
          <h3>{t("health.dci.arterialGasEmbolism.description")}</h3>

          <h2>{t("health.dci.commonSymptomsAndMechanisms.title")}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.0.item"
                )}`,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.1.item"
                )}`,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.2.item"
                )}`,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.3.item"
                )}`,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.4.item"
                )}`,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.5.item"
                )}`,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "health.dci.commonSymptomsAndMechanisms.listItems.6.item"
                )}`,
              }}
            ></li>
          </ul>

          <h2>{t("health.dci.diagnosingDCI.title")}</h2>
          <h3>{t("health.dci.diagnosingDCI.description")}</h3>

          <h2>{t("health.dci.treatingDCI.title")}</h2>
          <h3>
            <h3>
              <b style={{ color: "var(--blue5)" }}>
                {t("health.dci.treatingDCI.treatments.0.title")}{" "}
              </b>
              {t("health.dci.treatingDCI.treatments.0.details")}
            </h3>
            <h3>
              <b style={{ color: "var(--blue5)" }}>
                {t("health.dci.treatingDCI.treatments.1.title")}{" "}
              </b>
              {t("health.dci.treatingDCI.treatments.1.details")}
            </h3>
            <h3>
              <b style={{ color: "var(--blue5)" }}>
                {t("health.dci.treatingDCI.treatments.2.title")}{" "}
              </b>
              {t("health.dci.treatingDCI.treatments.2.details")}
            </h3>

            <h3>
              <b style={{ color: "var(--red1)" }}>
                {t("health.dci.treatingDCI.treatments.3.title")}{" "}
              </b>
              {t("health.dci.treatingDCI.treatments.3.details")}
            </h3>
            <h3>
              <b style={{ color: "var(--red1)" }}>
                {t("health.dci.treatingDCI.treatments.4.title")}{" "}
              </b>
              {t("health.dci.treatingDCI.treatments.4.details")}
            </h3>
          </h3>

          <h2> {t("health.dci.hyperbaricChamberTreatment.title")}</h2>
          <ul>
            <li>{t("health.dci.hyperbaricChamberTreatment.steps.0")}</li>
            <li>{t("health.dci.hyperbaricChamberTreatment.steps.1")}</li>
          </ul>
          <h2>{t("health.dci.preventingDCI.title")}</h2>
          <h3>{t("health.dci.preventingDCI.note")}</h3>
          <ul>
            <li>{t("health.dci.preventingDCI.tips.0")}</li>
            <li>{t("health.dci.preventingDCI.tips.0")}</li>
            <li>{t("health.dci.preventingDCI.tips.1")}</li>
            <li>{t("health.dci.preventingDCI.tips.2")}</li>
            <li>{t("health.dci.preventingDCI.tips.3")}</li>
            <li>{t("health.dci.preventingDCI.tips.4")}</li>
            <li>{t("health.dci.preventingDCI.tips.5")}</li>
            <li>{t("health.dci.preventingDCI.tips.6")}</li>
            <li>{t("health.dci.preventingDCI.tips.7")}</li>
            <li>{t("health.dci.preventingDCI.tips.8")}</li>
            <li>{t("health.dci.preventingDCI.tips.9")}</li>
            <li>{t("health.dci.preventingDCI.tips.10")}</li>
            <li>{t("health.dci.preventingDCI.tips.11")}</li>
            <li>{t("health.dci.preventingDCI.tips.12")}</li>
            <li>{t("health.dci.preventingDCI.tips.13")}</li>
            <li>{t("health.dci.preventingDCI.tips.14")}</li>
            <li>{t("health.dci.preventingDCI.tips.15")}</li>
            <li>{t("health.dci.preventingDCI.tips.16")}</li>
            <li>{t("health.dci.preventingDCI.tips.17")}</li>
            <li>{t("health.dci.preventingDCI.tips.18")}</li>
          </ul>

          <h2>{t("health.dci.healthInsuranceNote.title")}</h2>
          <h3>{t("health.dci.healthInsuranceNote.content")}</h3>

          <h2>{t("health.dci.suspectedDCI.title")}</h2>
          <h3>{t("health.dci.suspectedDCI.content")}</h3>
        </VerticalView>
      </BorderView>
    </div>
  );
};

export default DCI;
