import "./ui.styles.css"

const Line = (props: { color: string, width?: string }): React.ReactElement => {
    return (
        <div id="lineSplitter" style={{ backgroundColor: props.color, width: props.width ? props.width : "" }}>
        </div >
    );

}

export default Line;

export const VerticalLine = (props: { color: string }): React.ReactElement => {
    return (
        <div id="verticalLineSplitter" style={{ backgroundColor: props.color }}>
        </div >
    );

}