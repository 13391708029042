import { getUrl } from "../config/url";
import { loginEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID, removeTokens } from "../config/localStorage";
import { ICenter } from "../models/user";


export const submitLogin = async (username: string, password: string) : Promise<ICenter> => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  let urlencoded: URLSearchParams = new URLSearchParams();
  urlencoded.append("username", username);
  urlencoded.append("password", password);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded, 
  };

  //to avoid cache issue I added timeStamp 
  const response = await fetch(getUrl(loginEndPoint)+`?timeStamp=${ new Date().getTime().toString()}`, requestOptions)

  return response.json();
};

export const isLogged = () : boolean => {
  if ( getToken() && getTokenID() && getUUID() ){
    return true;
  }
  else{
    return false;
  }
  
};

export const logout = () => {
  removeTokens();
};