
const mediaImages = [
    "/media/Image.jpg",
    "/media/Image 2.jpg",
    "/media/Image 3.jpg",
    "/media/Image 4.jpg",
    "/media/Image 5.jpg",
    "/media/Image 6.jpg",
    "/media/Image 7.jpg",
    "/media/Image 8.jpg",
    "/media/Image 9.jpg",
    "/media/Image 10.jpg",
    "/media/Image 11.jpg",
    "/media/Image 12.jpg",
    "/media/Image 13.jpg",
    "/media/Image 14.jpg",
    "/media/Image 15.jpg",
    "/media/Image 16.jpg",
    "/media/Image 17.jpg",
    "/media/Image 18.jpg",
];

export default mediaImages