import { ICenter } from "../models/user";

export const setToken = (token?: string) => {
  localStorage.setItem("token", token ?? "");
}
export const setTokenID = (tokenId?: number) => {
  localStorage.setItem("token_id", tokenId?.toString() ?? "");
}
export const setUUID = (_uuid?: string) => {
  localStorage.setItem("uuid", _uuid ?? "");
}
export const setUser = (userInfo: ICenter) => {
  localStorage.setItem("user", JSON.stringify(userInfo) ?? "");
}

export const getToken = () =>  localStorage.getItem('token') as string;
export const getTokenID = () => localStorage.getItem('token_id') as string;
export const getUUID = () => localStorage.getItem('uuid') as string;
export const getUser = () => JSON.parse(localStorage.getItem('user') as string) as ICenter;


export const removeTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('token_id');
  localStorage.removeItem('uuid');
}

export const setLanguage = (lang?: string) => {
  localStorage.setItem("language", lang ?? "");
}
export const getLanguage = () =>  localStorage.getItem('language') as string;

