import React, { useEffect, useState } from 'react';
import { IGuest } from '../../network/models/guest';
import { sendGustLinkToEmail } from '../../network/guests/sendGuestLink';
const newLine = '%0D%0A';
const ShareLinkModal = ({ link, showModal, guest, onClose }: {
  link: string,
  showModal: boolean,
  onClose: () => void,
  guest: IGuest | undefined,
}) => {
  const [messageBody, setMessageBody] = useState<string>(link);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  useEffect(() => {
    setMessageBody(`Hi ${guest?.name.split(' ')[0]}, ${newLine}

Thank you for your support and cooperation.${newLine}

Kindly open the link and find your membership details.${newLine}

${link}${newLine}

DECO WORLD is always here for you when you need us.${newLine}

Be Safe${newLine}
Enjoy Your Diving Trip${newLine}
`);
  }, [guest, link])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  const handleShareWhatsApp = () => {
    if (!guest) {
      return;
    }
    let phone = guest?.phone ?? '';
    if (guest?.phone.startsWith('01')) {
      phone = `2${guest?.phone}`
    }
    let sendToPhone = guest?.phone !== '' ? `phone=${phone}&` : '';

    sendToPhone = sendToPhone.replace("+", "");
    sendToPhone = sendToPhone.startsWith('00') ? sendToPhone.replace('00', '') : sendToPhone;
    window.open(`https://api.whatsapp.com/send?${sendToPhone}text=${messageBody}`);
  };

  const handleShareSMS = () => {
    window.open(`sms:${guest?.phone ?? ''}&body=${messageBody}`);
  };

  const handleShareEmail = () => {
    try {
      if (guest)
        sendGustLinkToEmail(guest);
      setEmailSent(true);
      alert('The email is being sent. This could take some time');
    } catch (error) {
      alert('Error sending email');
    }
  };
  if (showModal) {
    return (
      <div className="backgroundBlur">
        <button onClick={() => { }}>Share Link</button>
        <div className="shareContainer">
          <img onClick={onClose} src="/icons/cancel.png" className="close" alt="edit info" />
          <label className='linkLabel'>{link}</label>
          <div>
            <button
              disabled={linkCopied}
              className={`shareButton ${linkCopied && 'buttonDisabled'}`}
              onClick={handleCopyLink}>
              Copy Link  {linkCopied && '✓'}
            </button>
            <button
              disabled={emailSent}
              className={`shareButton ${emailSent && 'buttonDisabled'}`}
              onClick={handleShareEmail}>
              Share via Email {emailSent && '✓'}
            </button>
          </div>
          <div>
            <button className="shareButton" onClick={handleShareWhatsApp}>Share on WhatsApp</button>
            <button className="shareButton" onClick={handleShareSMS}>Share on SMS</button>
          </div>
          <button className="closeButton" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }
  return null;
};

export default ShareLinkModal;
