import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getInsurance } from '../../network/guests/getInurance';
import { IGuest } from '../../network/models/guest';
import CSP from '../csp';
import { twoDigits, getDateFormat, getHourFormat } from '../../utils/time';

const dummyGuest: IGuest = {
  id: 0,
  name: "",
  passport_id_number: "",
  email: "",
  phone: "",
  birth_date: "2000-01-01",
  created: "2022-01-01T00:00:00Z",
  paid: false,
  center_id: 0,
  uuid: "",
  updated: "2022-01-01T00:00:00Z",
  payment_date: "2022-01-01",
  days: 0,
  minutes: 0,
  code: "",
  };

export const getRemainingTimeFormat = (minutes: number, created: string): string => {
  const createdDate = new Date(created);
  const today = new Date();
  const timeDifferenceMillis = today.getTime() - createdDate.getTime();
  // Convert the difference to days
  const daysCount = Math.floor(timeDifferenceMillis / (1000 * 60 * 60 * 24));
  if (daysCount > 21) {
    return `-`
  }

  const allRemainingMinutes = (21*24*60-minutes);
  const remainingDays = Math.floor(allRemainingMinutes  / (24 * 60));
  const remainingHours = Math.floor(allRemainingMinutes  / (60)) - (remainingDays * 24) ;
  const remainingMinutes = allRemainingMinutes - ((remainingDays*60*24) + (remainingHours*60));
  if(remainingDays < 0) {
    return `-`
  }
  return `${twoDigits(remainingDays)} : ${twoDigits(remainingHours)} : ${twoDigits(remainingMinutes)}`;
}

export const getEndDate = (date: string) : Date => {
  let endDate = new Date(date);
  const daysToAdd = 21;
  endDate.setDate(endDate.getDate() + daysToAdd);
  return endDate;
}
const GuestDetails = () => {
  const [guest, setGuest] = useState<IGuest>(dummyGuest);
  const location = useLocation();
  const url = location.pathname;
  let code = url.replace(/\/g\//, '');
  const allRemainingMinutes = (21*24*60-guest.minutes);
  const remainingDays = Math.floor(allRemainingMinutes  / (24 * 60));
  const remainingHours = Math.floor(allRemainingMinutes  / (60)) - (remainingDays * 24) ;
  const remainingMinutes = allRemainingMinutes - ((remainingDays*60*24) + (remainingHours*60));

  let endDate = getEndDate(guest.created);

  useEffect(() => {
    const fetchGuest = async () => {
      getInsurance(code).then((guest) =>{
        setGuest(guest);
document?.getElementById('cspTitleContainerId')?.focus();
      } );
    };
    fetchGuest();
  }, [code]);

  return (
    <div className="container"  >
      <div className="cspTitleContainer" id="cspTitleContainerId" tabIndex={-1}>
      <h1 className="title"> CSP Card</h1>
      </div>
       <table className="guestDetailsTable">
       <tr>
          <th>{"Basic Information"}</th>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Name</td>
          <td>{guest.name}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Email</td>
          <td>{guest.email}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Phone</td>
          <td>{guest.phone}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Passport ID</td>
          <td>{guest.passport_id_number}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Birth Date</td>
          <td>{getDateFormat(guest.birth_date)}</td>
        </tr>
        <tr>
          <th>{"Subscription Information"}</th>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Started</td>
          <td>{getDateFormat(guest.created)}</td>
          <td>{getHourFormat(guest.created)}</td>

        </tr>
        <tr className={'cyanBackground'}>
          <td>End Date</td>
          <td>{getDateFormat(endDate)}</td>
          <td>{getHourFormat(endDate)}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Remaining Days</td>
          <td>{remainingDays}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Remaining Hours</td>
          <td>{remainingHours}</td>
        </tr>
        <tr className={'cyanBackground'}>
          <td>Remaining Minutes</td>
          <td>{remainingMinutes}</td>
        </tr>
        
      </table>
      <CSP/>
    </div>
  );
};

export default GuestDetails;
