import React, { useCallback, useState } from 'react';
import { changePassword } from '../../network/auth/changePassword';
const PasswordModal = ({ showModal, onClose }: {
  showModal: boolean,
  onClose: () => void,
}) => {
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");

  const handleSubmit = useCallback(
    () => {
      setError('');
      if (password === passwordConfirm && password.length >= 6) {
        changePassword(password);
        alert("Password updated successfully.");
        onClose();
      } else if (password.length < 6) {
        setError("Make sure to use a password with at least 6 characters.");
      } else {
        setError("Make sure the Password and Confirm fields are identical.");
      }
    },
    [onClose, password, passwordConfirm],
  );


  return (
    <div>
      {showModal && (
        <div className="backgroundBlur">
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="titleContainer">
              <h1 className="title">Change Password</h1>
              <img onClick={onClose} src="/icons/cancel.png" className="close" alt="edit info" />
            </div>

            <div className="fields">
              <div className="formRow">
                <label id="passwordLabel" className="formLabel" htmlFor="password">
                  Password :
                </label>
                <input
                  autoFocus
                  type="password"
                  className="formInput"
                  name="name"
                  id="name"
                  value={password}
                  onChange={(v) => setPassword(v.target.value)}
                />
              </div>
              <div className="formRow">
                <label id="passwordConfirmLabel" className="formLabel" htmlFor="password">
                  Confirm :
                </label>
                <input
                  type="password"
                  className="formInput"
                  name="name"
                  id="name"
                  value={passwordConfirm}
                  onChange={(v) => setPasswordConfirm(v.target.value)}
                />
              </div>
              <label className="errorTextRow">
                {error}
              </label>
              <button onClick={handleSubmit} className="submitButton" type="button">
                Update
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PasswordModal;
