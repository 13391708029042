import "./ContentViews.css"

export const HorizontalView = (props: { children: React.ReactNode, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div id="horizontalViewContainer" style={props.style}>
            {props.children}
        </div>
    );

}

export const VerticalView = (props: { children: React.ReactNode, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div id="verticalViewContainer" style={props.style}>
            {props.children}
        </div>
    );

}
