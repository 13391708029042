import { useState, useEffect } from "react";
import { getPageItem, useMenuPages } from "../../controller/pages";
import { R } from "../../res";
import PhoneNumbersMenu from "../ui/phoneNumbersMenu";
import "./footer.styles.css";
import { useTranslation } from "react-i18next";

function FooterItem(props: { link: string; text: string }) {
  const isThisLink = props.link.includes(R.string.routes.health);
  return (
    <li
      key={props.link}
      className={`footerItem ${isThisLink ? " active" : ""}`}
    >
      <a className={isThisLink ? "active" : ""} href={props.link}>
        {props.text}
      </a>
    </li>
  );
}

function Footer() {
  const [dropMenuOpened, setDropMenu] = useState(false);
  const { t } = useTranslation();
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 960px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 960px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);
  const pages = useMenuPages();
  return (
    <footer>
      <div id="footerContainerDiv">
        {isDesktop ? (
          <>
            <ul id="primary" className="footerStrip">
              {pages.map((page) => (
                <FooterItem
                  key={`primary${page.link}`}
                  link={page.link}
                  text={t(page.name).toUpperCase()}
                />
              ))}
            </ul>
            <ul id="secondary" className="footerStrip">
              {getPageItem(R.string.routes.health).map((page) =>
                page.subPages
                  ? page.subPages.map((page) => (
                      <FooterItem
                        key={`secondary${page.link}`}
                        link={page.link}
                        text={t(page.name).toUpperCase()}
                      />
                    ))
                  : ""
              )}
            </ul>
            <div id="socialMedia" className="footerStrip">
              <a href={R.string.contacts.facebook} className="iconSocialMedia">
                <img src="/icons/facebook.png" alt="Facebook" />
              </a>
              <a
                href={R.string.contacts.whatsappLink}
                className="iconSocialMedia"
              >
                <img src="/icons/whatsapp.png" alt="Whatsapp" />
              </a>
              <a href={R.string.contacts.mailLink} className="iconSocialMedia">
                <img src="/icons/mail.png" alt="Mail" />
              </a>
              <a
                href={R.string.routes.void}
                className="iconSocialMedia"
                onMouseOver={() => setDropMenu(true)}
                onMouseOut={() => setDropMenu(false)}
              >
                <img src="/icons/phone.png" alt="Phone" />
              </a>
              {/* <PhoneNumbersMenu isDesktop={true} /> */}
              {dropMenuOpened ? (
                <PhoneNumbersMenu
                  functionSetDropMenu={setDropMenu}
                  isDesktop={true}
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
        <div id="copyrights" className="footerStrip">
          © {(new Date()).getFullYear()} {t("menu.byDecoWorld")}
        </div>
        <div className="footerStrip" style={{backgroundColor: "var(--blue7)", padding:5}}>
        
        </div>
      </div>
    </footer>
  );
}

export default Footer;
