import { ReactNode } from "react";
import "./WaveView.css"

const WaveView = (props: { children: ReactNode, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div id="waveViewContainer" style={props.style}>
            <div className="waveViewLine" id="waveViewLineUp">
                <img alt="wave" src="/Wave.svg" />
                {/* <img src="Wave.svg" /> */}
            </div>

            <div id="waveTextContainer">
                {props.children}
            </div>

            <div className="waveViewLine" id="waveViewLineDown" >
                <img alt="wave" src="/Wave.svg" />
                {/* <img src="Wave.svg" /> */}
            </div>
        </div>
    );

}

export default WaveView;