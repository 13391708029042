

import { useLocation } from 'react-router-dom';
import { R } from '../../res'
import { isDashboardPage } from '../../controller/pages';
import { useEffect, useState } from 'react';

const FloatIcon = (props: { src: string, text: string, color: string, href: string }) => {
    return (
        <div className="floatIcon">
            <a href={props.href}>
                <img width="25px" height="25px" alt={props.text} src={props.src} />
                <text style={{ color: props.color }}>{props.text}</text>
            </a>
        </div>
    )
}


const FloatingButtons = () => {
    const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 960px)").matches)
    useEffect(() => {
        window
            .matchMedia("(min-width: 960px)")
            .addEventListener('change', e => setIsDesktop(e.matches));
    }, []);
    const { pathname } = useLocation();
    if (!isDesktop && isDashboardPage(pathname)) {
        return (
            <div></div>
        )
    }
    return (
        <div className="float" >
            <FloatIcon href={R.string.contacts.whatsappLink} src={"/icons/whatsapp-b.png"} text={"WHATSAPP"} color={'var(--blue3'} />
            <FloatIcon href={R.string.contacts.mailLink} src={"/icons/mail-b.png"} text={"MAIL"} color={'var(--blue3'} />
            <FloatIcon href={R.string.contacts.hotlineLink} src={"/icons/phone-r.png"} text={"HOTLINE"} color={'var(--red1'} />
        </div>
    )
}

export default FloatingButtons