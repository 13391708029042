import React, { useRef, useState } from 'react';
import { IGuest } from '../../network/models/guest';
import { getDateFormat, getHourFormat } from '../../utils/time';
import { getEndDate, getRemainingTimeFormat } from './GuestDetails';
import useDesktop from '../../hooks/useDesktop';
import Spinner from '../../components/ui/spinner';

const BulkPopup = ({ showModal, guests, onClose, onConfirm, confirmButtonText }: {
  showModal: boolean,
  onClose: () => void,
  onConfirm: () => Promise<void>,
  guests: IGuest[],
  confirmButtonText?: string,
}) => {

  const isDesktop = useDesktop();
  const [loading, setLoading] = useState(false);
  const tableElement = useRef<HTMLTableElement>(null);

  if (showModal) {
    return (
      <div className="backgroundBlur">
        <button onClick={() => { }}>Share Link</button>
        <div className="shareContainer"  style={{ maxHeight:'80vh' }}>
       
          <img onClick={onClose} src="/icons/cancel.png" className="close" alt="edit info" />
          <div   style={{ overflow:'auto' }}>
             <table id="tblGusts" ref={tableElement} className='tableStyle'>
            <thead>
              {isDesktop &&
                <tr style={{position: 'sticky' , top:' 0px' }} >
                  <th className="color6">ID</th>
                  <th className="color5">Name</th>
                  <th className="color4">Birth Date</th>
                  <th className="color3">REMAINING TIME</th>
                  <th className="color2">REGISTRATION DATE</th>
                  <th className="color1">EXPIRATION DATE</th>
                </tr>
              }
            </thead>
            <tbody>
              {guests.map((guest, index) => (
                <tr className={`${index % 2 !== 0 && "cyanBackground"}`} key={guest.id}>
                  {!isDesktop && <th className="color6">ID</th>}
                  <td>{index+1}</td>
                  {!isDesktop && <th className="color5">Name</th>}
                  <td>{guest.name}</td>
                  {!isDesktop && <th className="color4">Birth Date</th>}
                  <td>{getDateFormat(guest.birth_date)}</td>
                  {!isDesktop && <th className="color3">REMAINING TIME</th>}
                  <td>{getRemainingTimeFormat(guest.minutes, guest.created)}</td>
                  {!isDesktop && <th className="color2">REGISTRATION DATE</th>}
                  <td>{getDateFormat(guest.created)}   , at   {getHourFormat(guest.created)}</td>
                  {!isDesktop && <th className="color1">EXPIRATION DATE</th>}
                  <td>{getDateFormat(getEndDate(guest.created))}</td>
                </tr>
              ))}
            </tbody>
          </table></div>
          <div className="formButtonsRow">
            <button
              onClick={async () => {
                setLoading(true);
                await onConfirm();
                setLoading(false);
               // onClose();
              }}
            >
              {
                loading
                  ? <Spinner />
                  : <text>
                    {confirmButtonText ?? 'Execute'}
                  </text>
              }
            </button>

            <button
              onClick={  () => { 
                 const content:any =`<!DOCTYPE html>
                 <html>
                 <head>
                 <style>
                 table, td, th {
                   border: 1px solid;
                 }
                 
                 table {
                   width: 100%;
                   border-collapse: collapse;
                 }
                 </style>
                 </head>
                 <body> ${tableElement.current?.outerHTML}</body>
                 </html> `;
                 if(document!=null){
                  const ifr:any =document?.getElementById("ifmcontentstoprint");
                  const pri:any = ifr?.contentWindow;
                  pri.document.open();
                  pri.document.write(content );
                  pri.document.close();
                  pri.focus();
                  pri.print();
                 }

              }}
            > Print
            </button>
            <iframe id="ifmcontentstoprint" style={{"height": "0px", "width": "0px", "position": "absolute"}}></iframe>
          </div>
          
        </div>
      </div>
    );
  }
  return null;
};

export default BulkPopup;
