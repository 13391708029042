import { getUrl } from "../config/url";
import { setGuestsPaymentEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";
import { IGuest, INewGuest } from "../models/guest";


export const setMultipleGuestsPayment = async (guests: IGuest[], pay : boolean = true ): Promise<INewGuest[]> => {

  var myHeaders = new Headers();
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);


  let ids = '';
  guests.forEach((guest, index) => {
    const separator = (index < guests.length - 1) ? ',' : '';
    ids += guest.uuid + separator;
  });

  var urlencoded = new URLSearchParams();
  urlencoded.append("ids", ids.toString());
  urlencoded.append("paid", pay.toString());
  console.log(ids)
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };
    //to avoid cache issue I added timeStamp 
    const response = await fetch(`${getUrl(setGuestsPaymentEndPoint)}?timeStamp=${ new Date().getTime().toString()}`, requestOptions)
    const obj = await response.json();
    const data: IGuest[] = await obj.data;

  return data;

};
