import { getUrl } from "../config/url";
import { editInfoEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";
import { IUser } from "../models/user";

export const editInfo = async (user?: IUser) : Promise<IUser> => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  console.log(user?.image)
  var urlencoded = new URLSearchParams();

  if(user){
    if(user.name){
      urlencoded.append("name", user.name);
    }
    if(user.email){
      urlencoded.append("email", user.email);
    }
    if(user.status != null){
      urlencoded.append("status", user.status + "");
    }
    if(user.website){
      urlencoded.append("website", user.website);
    }
    if(user.location){
      urlencoded.append("location", user.location);
    }
    if(user.map){
      urlencoded.append("map", user.map);
    }
    if(user.manager){
      urlencoded.append("manager", user.manager);
    }
    if(user.mobile){
      urlencoded.append("mobile", user.mobile);
    }
    if(user.center_area){
      urlencoded.append("center_area", user.center_area);
    }
    if(user.image){
      urlencoded.append("image", user.image);
    }
  }

  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };

  //to avoid cache issue I added timeStamp 
  const response = await fetch(getUrl(editInfoEndPoint)+`?timeStamp=${ new Date().getTime().toString()}`, requestOptions);

  return response.json();
};
