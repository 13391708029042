export const loginEndPoint: string = "auth/login";
export const addGuestEndPoint: string = "gusts/add";
export const getGuestsEndPoint: string =
  "gusts/index";
export const myInsuranceEndPoint: string = "public/myinsuranceinfo/";
export const searchInsuranceEndPoint: string = "public/searchinsuranceinfo";
export const setGuestPaymentEndPoint: string = "gusts/setgustpayment";
export const setGuestsPaymentEndPoint: string = "gusts/setgustspayment";
export const sendGustLinkEndPoint: string = "gusts/sendGustLink";
export const editInfoEndPoint: string = "centers/editinfo";
export const changePasswordEndPoint: string = "centers/changepassword";
export const getPartnersEndPoint: string = "public/getpartners";
