import { useState } from "react";

const ToggleItem = (props: { children: React.ReactNode; title: string }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={`toggleItem ${expanded ? "expanded" : ""}`}>
      <div onClick={() => setExpanded((prev: boolean) => !prev)}>
        {props.title}{" "}
        <img
          className="expandIcon"
          height="14px"
          alt="Expand"
          src="/icons/next.png"
        ></img>
      </div>
      {expanded ? `${props.children}` : ""}
    </div>
  );
};

export default ToggleItem;
