import React, { useEffect, useState } from "react";
import Line, { VerticalLine } from "../components/ui/line";
import { Box, RoundedBox } from "../components/ui/roundedBox";
import { RoundedButton } from "../components/ui/roundedButton";
import BorderView from "../components/views/BorderView";
import { HorizontalView, VerticalView } from "../components/views/ContentViews";
import ImageCover from "../components/views/ImageCover";
import { R } from "../res";
import { useTranslation } from "react-i18next";

const CSP = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 960px)").matches
  );
  const { t } = useTranslation();
  useEffect(() => {
    window
      .matchMedia("(min-width: 960px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);
  return (
    <div>
      <ImageCover alt={"CSP"} src={"/covers/csp.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("csp.title")}
            <Line color="var(--blue4)" />
          </h1>
          <p>{t("csp.paragraph.0")}</p>
          <p>{t("csp.paragraph.1")}</p>
          <p>{t("csp.paragraph.2")}</p>
        </VerticalView>
      </BorderView>
      <Box>
        <HorizontalView
          style={{ flex: "8", margin: isDesktop ? "0 20px 0 0 " : "40px" }}
        >
          <RoundedBox
            style={{ padding: "10px", height: isDesktop ? "200px" : "360px" }}
          >
            <HorizontalView>
              <HorizontalView style={{ flex: "5" }}>
                <VerticalView>
                  <h1>{t("csp.Exclusions")}</h1>
                  <p style={{ fontWeight: "400" }}>
                    {t("csp.ExclusionDescription")}
                  </p>
                </VerticalView>
                <VerticalLine color={"white"} />
              </HorizontalView>
              <div style={{ flex: "8", fontWeight: "400" }}>
                <h4>{t("csp.ExclusionsPoints.0")}</h4>
                <h4>{t("csp.ExclusionsPoints.1")}</h4>
                <h4>{t("csp.ExclusionsPoints.2")}</h4>
                <h4>{t("csp.ExclusionsPoints.3")}</h4>
                <h4>{t("csp.ExclusionsPoints.4")}</h4>
                <h4>{t("csp.ExclusionsPoints.5")}</h4>
                <h4>{t("csp.ExclusionsPoints.6")}</h4>
                <h4>{t("csp.ExclusionsPoints.7")}</h4>
                <h4>{t("csp.ExclusionsPoints.8")}</h4>
              </div>
            </HorizontalView>
          </RoundedBox>
        </HorizontalView>
        <HorizontalView
          style={{ flex: "3", margin: isDesktop ? "0 0 0 20px" : "20px" }}
        >
          <RoundedButton
            href={R.string.routes.login}
            style={{
              padding: "10px",
              height: "200px",
              backgroundColor: "var(--blue5)",
            }}
          >
            <VerticalView>
              <text style={{ fontSize: "38px", textAlign: "center" }}>
                {t("csp.cspLogin")}
                <br />
                {t("csp.forCenters")}
              </text>
              <Line color={"white"} />
              <h2 style={{ fontSize: "26px", fontWeight: "400" }}>
                {" "}
                {t("csp.clickHere")}
              </h2>
            </VerticalView>
          </RoundedButton>
        </HorizontalView>
      </Box>
    </div>
  );
};

export default CSP;
