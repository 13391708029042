
export const RoundedButton = (props: { children: React.ReactNode, href: string, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <a href={props.href} className="roundedButton" style={props.style}> {props.children}</a>
    );
}

export const OutlinedButton = (props: { children: React.ReactNode, href: string, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div className="outlinedButton" style={props.style}> {props.children}</div>
    );
}

