import { useTranslation } from "react-i18next";
import Line from "../../components/ui/line";
import { Box } from "../../components/ui/roundedBox";
import { RoundedButton } from "../../components/ui/roundedButton";
import BorderView from "../../components/views/BorderView";
import { VerticalView } from "../../components/views/ContentViews";
import ImageCover from "../../components/views/ImageCover";
import { R } from "../../res";

const DM = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"DES"} src={"/covers/health_dm.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("health.dm.title")}
            <Line color="var(--blue4)" />
          </h1>
          <text>
            <h3>
              <b>{t("health.dm.subtitle")}</b>
            </h3>
            <ul>
              <li>{t("health.dm.points.0")}</li>
              <li>{t("health.dm.points.1")}</li>
              <li>{t("health.dm.points.2")}</li>
              <li>{t("health.dm.points.3")}</li>
            </ul>
          </text>
        </VerticalView>
      </BorderView>
      <Box>
        <RoundedButton href={R.string.routes.healthDci}>
        {t("health.dm.dci")}
          <img
            style={{ margin: "1rem" }}
            height="43px"
            alt="Go"
            src="/icons/arrow-right.png"
          ></img>
        </RoundedButton>
      </Box>
    </div>
  );
};

export default DM;
