import { useTranslation } from "react-i18next";
import BorderView from "../components/views/BorderView";
import { VerticalView } from "../components/views/ContentViews";
import { GridView } from "../components/views/GridView";
import { ImageGrid } from "../components/views/ImageCover";
import mediaImages from "../controller/media";

const Media = () => {
  const { t } = useTranslation();
  return (
    <div>
      <BorderView>
        <VerticalView>
          <p>{t("media.paragraph.0")}</p>
          <p>{t("media.paragraph.1")}</p>
          <p>{t("media.paragraph.2")}</p>
          <p>{t("media.paragraph.3")}</p>
        </VerticalView>
      </BorderView>
      <GridView>
        {mediaImages.map((imagePath) => {
          return (
            <ImageGrid
              key={imagePath}
              alt={"media"}
              src={imagePath}
            ></ImageGrid>
          );
        })}
      </GridView>
    </div>
  );
};

export default Media;
