import { getUrl } from "../config/url";
import { sendGustLinkEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";
import { IGuest } from "../models/guest";


export const sendGustLinkToEmail = async (guest: IGuest ) => {

  var myHeaders = new Headers();
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
  };
  //to avoid cache issue I added timeStamp 
  await fetch(`${getUrl(sendGustLinkEndPoint)}/${guest.uuid}?timeStamp=${ new Date().getTime().toString()}`, requestOptions)
};
