import Line, { VerticalLine } from "../components/ui/line";
import BorderView from "../components/views/BorderView";
import { HorizontalView, VerticalView } from "../components/views/ContentViews";
import ImageCover from "../components/views/ImageCover";
import WaveView from "../components/views/WaveView";
import { useEffect, useState } from "react";
import { OutlinedButton, RoundedButton } from "../components/ui/roundedButton";
import { R } from "../res";
import "../components/footer/footer.styles.css";
import PhoneNumbersMenu from "../components/ui/phoneNumbersMenu";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

const commonButtonStyle = {
  width: "fit-content",
  fontSize: "22px",
  fontWeight: "500",
  borderRadius: ".6rem",
  padding: "8px",
};

const MailAndHotline = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 style={{ textAlign: "center" }}>
        {t("contact.email")}: {R.string.contacts.mail}
      </h4>
      <Line color="var(--blue3)" />
      <h4>
        {t("contact.hotline")}: {R.string.contacts.hotline}
      </h4>
    </>
  );
};

const Home = () => {
  const [dropMenuOpened, setDropMenu] = useState(false);
  const { t } = useTranslation();
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 960px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 960px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);
  return (
    <div>
      <ImageCover alt={"About"} src={"/covers/landing.jpg"} />
      <BorderView>
        <HorizontalView>
          <h1>{t("home.about")}</h1>
          <VerticalLine color="var(--blue4)" />
          <h3>{t("home.description")}</h3>
        </HorizontalView>
      </BorderView>

      <WaveView
        style={{
          backgroundImage: "url('/covers/diver.png')",
          backgroundColor: "#0A31A5",
        }}
      >
        <VerticalView style={{ marginBottom: "40px" }}>
          <OutlinedButton
            style={{
              margin: "40px",
              width: "220px",
              fontSize: "28px",
              borderRadius: "1rem",
              padding: "6px",
            }}
            href={"/health"}
          >
            {t("home.healthAndMedicine")}
          </OutlinedButton>

          <HorizontalView>
            <RoundedButton
              style={{ ...commonButtonStyle, marginRight: "5px" }}
              href={"/health/des"}
            >
              {t("home.emergencyServices")}
            </RoundedButton>

            <RoundedButton
              style={{ ...commonButtonStyle, margin: "5px" }}
              href={"/health/dm"}
            >
              {t("home.divingMedicine")}
            </RoundedButton>

            <RoundedButton
              style={{ ...commonButtonStyle, margin: "5px" }}
              href={"/health/hbot"}
            >
              {t("home.hbo")}
            </RoundedButton>

            <RoundedButton
              style={{ ...commonButtonStyle, margin: "5px" }}
              href={"/health/ftd"}
            >
              {t("home.fitToDiveMedicalAssessment")}
            </RoundedButton>

            <RoundedButton
              style={{ ...commonButtonStyle, marginLeft: "5px" }}
              href={"/health/acs"}
            >
              {t("home.ambulatoryCareServices")}
            </RoundedButton>
          </HorizontalView>
        </VerticalView>
        {/* </Particles> */}
      </WaveView>

      <BorderView>
        <HorizontalView>
          <h1 style={{ textAlign: "center" }}>{t("contact.contactUs")}</h1>

          <VerticalLine color="var(--blue4)" />

          {isDesktop ? (
            <VerticalView>
              <MailAndHotline />
            </VerticalView>
          ) : (
            <MailAndHotline />
          )}

          <VerticalLine color="var(--blue4)" />
          <h4 style={{ textAlign: "center" }}>
            El Gouna : {R.string.contacts.elGouna}
            <br />
            Safaga : {R.string.contacts.safaga}
            <br />
            Port Ghaleb : {R.string.contacts.portGhaleb}
            <br />
            Hamata : {R.string.contacts.hamata}
            <br />
            Seychelles : {R.string.contacts.seychelles}
          </h4>
          {!isDesktop ? (
            <>
              <Line color="var(--blue4)" />
              <div style={{ margin: "10px" }}>
                <a
                  href={R.string.contacts.facebook}
                  className="iconSocialMedia"
                >
                  <img
                    style={{ transform: "translateY(3px)" }}
                    src="/icons/facebook.png"
                    alt="Facebook"
                  />
                </a>
                <a
                  href={R.string.contacts.whatsappLink}
                  className="iconSocialMedia"
                >
                  <img
                    style={{ transform: "translateY(3px)" }}
                    src="/icons/whatsapp.png"
                    alt="Whatsapp"
                  />
                </a>
                <a
                  href={R.string.contacts.mailLink}
                  className="iconSocialMedia"
                >
                  <img
                    style={{ transform: "translateY(3px)" }}
                    src="/icons/mail.png"
                    alt="Mail"
                  />
                </a>
                <a
                  href={R.string.routes.void}
                  className="iconSocialMedia"
                  onClick={() => setDropMenu((prev) => !prev)}
                >
                  <img
                    style={{ transform: "translateY(3px)" }}
                    src="/icons/phone.png"
                    alt="Phone"
                  />
                </a>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setDropMenu(false);
                  }}
                >
                  {dropMenuOpened ? (
                    <PhoneNumbersMenu
                      functionSetDropMenu={setDropMenu}
                      isDesktop={false}
                    />
                  ) : (
                    ""
                  )}
                </OutsideClickHandler>
              </div>
            </>
          ) : (
            ""
          )}
        </HorizontalView>
      </BorderView>
    </div>
  );
};

export default Home;
