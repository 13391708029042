import About from "../pages/about";
import CSP from "../pages/csp";
import ACS from "../pages/health/acs";
import DES from "../pages/health/des";
import Health from "../pages/health";
import DM from "../pages/health/dm";
import FTD from "../pages/health/ftd";
import HBOT from "../pages/health/hbot";
import Media from "../pages/media";
import Partners from "../pages/partners";
import Training from "../pages/training";
import DCI from "../pages/health/dci";
import Locations from "../pages/locations";
import { R } from "../res";
import LoginForm from "../pages/auth/login";
import GuestDetails from "../pages/dashboard/GuestDetails";
import EditInfoForm from "../pages/auth/editInfo";
import Center from "src/pages/dashboard/center";

export interface IPage {
  name: string;
  link: string;
  PageComponent: (props?: any) => JSX.Element;
  subPages?: IPage[];
}
const healthSubPages: IPage[] = [
  {
    link: R.string.routes.healthDes,
    name: "menu.health.des",
    PageComponent: DES,
  },
  {
    link: R.string.routes.healthDm,
    name: "menu.health.dm",
    PageComponent: DM,
    subPages: [
      {
        link: R.string.routes.healthDci,
        name: "menu.health.dci",
        PageComponent: DCI,
      },
    ],
  },
  {
    link: R.string.routes.healthHbot,
    name: "menu.health.hbot",
    PageComponent: HBOT,
  },
  {
    link: R.string.routes.healthFtd,
    name: "menu.health.ftd",
    PageComponent: FTD,
  },
  {
    link: R.string.routes.healthAcs,
    name: "menu.health.acs",
    PageComponent: ACS,
  },
];

export const useMenuPages = (): IPage[] => {
  return menuPages;
};
export const useloginPage = (): IPage[] => {
  return dashboardPages;
};

export const getAllPages = (): IPage[] => {
  const allPages: IPage[] = [];

  for (var i = 0; i < menuPages.length; i++) {
    allPages.push(..._getAllPages(menuPages[i]));
  }
  for (var i = 0; i < dashboardPages.length; i++) {
    allPages.push(..._getAllPages(dashboardPages[i]));
  }
  return allPages;
};

const _getAllPages = (page: IPage): IPage[] => {
  if (!page.subPages) {
    return [page];
  }
  const allPages = [];
  allPages.push(page);
  for (var i = 0; i < page.subPages.length; i++) {
    allPages.push(..._getAllPages(page.subPages[i]));
  }
  return allPages;
};

export const getPageItem = (link: string): IPage[] => {
  return menuPages.filter((value: IPage) => link.includes(value.link));
};

const menuPages: IPage[] = [
  {
    link: R.string.routes.about,
    name: "menu.about",
    PageComponent: About,
  },
  {
    link: R.string.routes.health,
    name: "menu.health.title",
    PageComponent: Health,
    subPages: healthSubPages,
  },
  {
    link: R.string.routes.training,
    name: "menu.training",
    PageComponent: Training,
  },
  {
    link: R.string.routes.csp,
    name: "menu.csp",
    PageComponent: CSP,
  },
  {
    link: R.string.routes.media,
    name: "menu.media",
    PageComponent: Media,
  },
  // {
  //     link: R.string.routes.supplies, name: 'Medical Supplies', PageComponent: Supplies,
  // },
  {
    link: R.string.routes.locations,
    name: "menu.locations",
    PageComponent: Locations,
  },
  {
    link: R.string.routes.partners,
    name: "menu.partners",
    PageComponent: Partners,
  },
  // {
  //     link: R.string.routes.doctors, name: 'Doctors', PageComponent: Doctors,
  // },
];

export const dashboardPages: IPage[] = [
  {
    link: R.string.routes.login,
    name: "menu.dashboard.login",
    PageComponent: LoginForm,
  },
  {
    link: R.string.routes.center,
    name: "menu.dashboard.center",
    PageComponent: Center,
  },

  {
    link: R.string.routes.guest,
    name: "menu.dashboard.guest",
    PageComponent: GuestDetails,
  },
  {
    link: R.string.routes.editInfo,
    name: "menu.dashboard.editInfo",
    PageComponent: EditInfoForm,
  },

];

const isAGuestPage = (input: string): boolean => {
  const regex = /^\/g\/[a-zA-Z0-9]+$/;
  return regex.test(input);
};

export const isDashboardPage = (route: string): boolean => {
  for (let i = 0; i < dashboardPages.length; i++) {
    if (route === dashboardPages[i].link || isAGuestPage(route)) {
      return true;
    }
  }
  return false;
}
