import React, { PropsWithChildren } from "react";
import Footer from "../footer";
import Navbar from "../navbar";
import FloatingButtons from "../ui/floatingButtons";
import "./layout.styles.css";

const Layout = (props: PropsWithChildren): React.ReactElement => {
  return (
    <div id="pageContainer">
      <Navbar />
      {props.children}
      <Footer />
      <FloatingButtons />
    </div>
  );
};

export default Layout;
