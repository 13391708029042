
export function timePassed(dateString: string | Date) {
  const targetTime = new Date(dateString);
  const now = new Date();

  if (targetTime.getTime() < now.getTime()) {
    return true;
  } else {
    return false;
  }
}


export function twoDigits(digit: number) {
  return digit.toLocaleString(undefined, { minimumIntegerDigits: 2 })
}

export function getDateFormat(dateString: string | Date) {
  const date = new Date(dateString);
  let day = date.getDate();
  let month = date.getMonth() + 1; // +1 as month starts from 0
  let year = date.getFullYear();
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${twoDigits(day)} - ${twoDigits(month)} - ${year}`;
}

export function getHourFormat(dateString: string | Date) {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? 0 + minutes : minutes;
  const strTime = twoDigits(hours) + ':' + twoDigits(minutes) + ' ' + ampm;
  return strTime;
}
