import { useCallback, useEffect, useState } from "react";
import "./center.styles.css";
import { setGuestPayment } from "../../network/guests/setGuestPayment";
import ShareLinkModal from "./SharePopup";
import { IGuest } from "../../network/models/guest";
import { Link } from "react-router-dom";
import { getDateFormat, timePassed, getHourFormat } from "../../utils/time";
import { getEndDate, getRemainingTimeFormat } from "./GuestDetails";
import useDesktop from "../../hooks/useDesktop";

export const Row = (props: { guest: IGuest; index: number, checked?: boolean, toggleChecked: (guest: IGuest) => void }) => {
  getDateFormat(props.guest.birth_date);
  const guestCode = props.guest.code;
  const link = `/g/${guestCode}`;
  const { id, name, birth_date, minutes, created, paid } = props.guest;
  const [isPaid, setIsPaid] = useState(paid);
  const [isPaymentEditable, setPaymentEditable] = useState(!paid);
  const [showShare, setShowShare] = useState(false);
  const userLink = `https://decoworld.org/g/${guestCode}`;
  const isDesktop = useDesktop();

  const handleCheckmarkClick = () => {
    if (isPaymentEditable || !isPaid) {
      setGuestPayment(props.guest, !isPaid)
        .then(
          () => {
            setPaymentEditable(true);
            setTimeout(() => {
              setPaymentEditable(false || isPaid);
            }, 5000);
            setIsPaid(!isPaid);
          }
        );
    }
  };
  useEffect(() => {
    setIsPaid(props.guest.paid);
    setPaymentEditable(false);
  }, [props.guest.paid]);

  const handleShare = useCallback(async () => {
    setShowShare(true);
  }, []);

  if (showShare) {
    return (
      <ShareLinkModal
        guest={props.guest}
        showModal={showShare}
        link={userLink}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  }
  return (
    <tr className={`${props.index % 2 === 0 && "cyanBackground"} 
            ${timePassed(getEndDate(created)) && "fadedRow"}`}>
      <td>
        <input type="checkbox" checked={props.checked ?? false}
          onChange={
            () => {
              props.toggleChecked(props.guest)
            }}
        />
      </td>
      {!isDesktop && <th className="color8">NO</th>}
      <td>{id}</td>
      {!isDesktop && name && <th className="color7">GUEST NAME</th>}
      <td>{name}</td>
      {!isDesktop && <th className="color6">DATE OF BIRTH</th>}
      <td>{getDateFormat(birth_date)}</td>
      {!isDesktop && <th className="color5">REMAINING TIME</th>}
      <td>{getRemainingTimeFormat(minutes, created)}</td>
      {!isDesktop && <th className="color4">REGISTRATION DATE</th>}
      <td>{getDateFormat(created)}   , at   {getHourFormat(created)}</td>
      {!isDesktop && <th className="color3">EXPIRATION DATE</th>}
      <td>{getDateFormat(getEndDate(created))}</td>
      {!isDesktop && <th className="color2">PAID</th>}
      <td>
        <button
          onClick={handleCheckmarkClick}
          className={`paidButton ${(!isPaymentEditable && isPaid) && "paidButtonDisabled"} ${isPaymentEditable && isPaid && "paidButtonEnabled"}`}>
          {isPaid ? "✓" : "X"}{isPaymentEditable && isPaid && "."}
        </button>
      </td>
      {!isDesktop && <th className="color1">SHARE</th>}
      <td>
        <img onClick={handleShare} src="/icons/share.png" className="share" alt="add guest" />
      </td>
      {isDesktop &&
        <td>
          <Link to={link}>Details</Link>
        </td>}
    </tr>
  );
};

export default Row;
