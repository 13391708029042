
export const RoundedBox = (props: { children: React.ReactNode, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div className="roundedBox" style={props.style}>
            {props.children}
        </div>
    );
}

export const Box = (props: { children: React.ReactNode, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div className="box" style={props.style}>
            {props.children}
        </div>
    );
}

