import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/layout";
import { getAllPages, IPage } from "./controller/pages";
import Home from "./pages";

function App() {
  const pages: IPage[] = getAllPages();
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          {pages.map((page: IPage) => {
            return (
              <Route
                key={`r${page.link}`}
                path={page.link}
                element={<page.PageComponent />}
              />
            );
          })}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
