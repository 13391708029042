import { useTranslation } from "react-i18next";
import Line from "../../components/ui/line";
import BorderView from "../../components/views/BorderView";
import { VerticalView } from "../../components/views/ContentViews";
import ImageCover from "../../components/views/ImageCover";

const HBOT = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"DES"} src={"/covers/health_hbot.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("health.hbot.title")}
            <Line color="var(--blue4)" />
          </h1>
          <text>
            <h3>{t("health.hbot.subtitle")}</h3>
            <ul>
              <li>{t("health.hbot.points.0")}</li>
              <li>{t("health.hbot.points.1")}</li>
              <li>{t("health.hbot.points.2")}</li>
              <li>{t("health.hbot.points.3")}</li>
              <li>{t("health.hbot.points.4")}</li>
              <li>{t("health.hbot.points.5")}</li>
              <li>{t("health.hbot.points.6")}</li>
              <li>{t("health.hbot.points.7")}</li>
              <li>{t("health.hbot.points.8")}</li>
              <li>{t("health.hbot.points.9")}</li>
              <li>{t("health.hbot.points.10")}</li>
              <li>{t("health.hbot.points.11")}</li>
              <li>{t("health.hbot.points.12")}</li>
              <li>{t("health.hbot.points.13")}</li>
              <li>{t("health.hbot.points.14")}</li>
            </ul>
          </text>
        </VerticalView>
      </BorderView>
    </div>
  );
};

export default HBOT;
