import Line, { VerticalLine } from "../components/ui/line";
import BorderView from "../components/views/BorderView";
import { HorizontalView } from "../components/views/ContentViews";
import ImageCover from "../components/views/ImageCover";
import WaveView from "../components/views/WaveView";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 960px)").matches
  );
  const { t } = useTranslation();
  useEffect(() => {
    window
      .matchMedia("(min-width: 960px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);
  return (
    <div>
      <ImageCover alt={"About"} src={"/covers/about.jpg"} />
      <BorderView>
        <HorizontalView>
          <h1>{t("about.about")}</h1>
          {isDesktop ? (
            <VerticalLine color="var(--blue4)" />
          ) : (
            <Line color="var(--blue4)" />
          )}
          <h3>{t("about.description")}</h3>
        </HorizontalView>
      </BorderView>

      <WaveView>
        <h1 style={{ textAlign: "center" }}>
          {t("about.organizationalValues.title")}
        </h1>
        <div>
          <h5>{t("about.organizationalValues.subtitle")}</h5>
          <Line color="white" />
        </div>
        <p>{t("about.organizationalValues.content")}</p>
      </WaveView>
    </div>
  );
};

export default About;
