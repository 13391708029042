import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IPage, isDashboardPage, useMenuPages } from "../../controller/pages";
import { isLogged } from "../../network/auth/login";
import { R } from "../../res";
import Line from "../ui/line";
import "./navbar.styles.css";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../footer/LanguageSelector";

function NavbarItem(props: { page: IPage }) {
  const link = props.page.link;
  const { t } = useTranslation();
  const text = t(props.page.name).toUpperCase();
  const subPages = props.page.subPages;
  const isThisLink = useLocation().pathname.includes(link);
  const [dropMenuOpened, setDropMenu] = useState(false);

  return (
    <li>
      <a
        href={subPages ? R.string.routes.void : link}
        onMouseOver={() => setDropMenu(true)}
        onMouseOut={() => setDropMenu(false)}
        className={`navbarItem ${
          isThisLink || dropMenuOpened ? " active" : ""
        }`}
      >
        <div className="line2"></div>
        <div className="line3"></div>
        <div className="line1"></div>
        <a
          className={`navbarItemText ${isThisLink ? "active" : ""}`}
          href={subPages ? R.string.routes.void : link}
        >
          {text}
        </a>
      </a>
      {subPages ? (
        <div
          onMouseOver={() => setDropMenu(true)}
          onMouseOut={() => setDropMenu(false)}
          className={dropMenuOpened ? "subMenu" : "subMenuHidden"}
        >
          <Line width="100px" color="var(--blue2)" />
          {subPages.map((page, index) => (
            <>
              <a
                key={`navbar1${page.link}`}
                className="subMenuItem"
                href={page.link}
              >
                {t(page.name).toUpperCase()}
              </a>
              {index < subPages.length - 1 && "•"}
            </>
          ))}
          <Line width="100px" color="var(--blue2)" />
        </div>
      ) : (
        ""
      )}
    </li>
  );
}

export const MobileMenu = (props: {
  pages: IPage[];
  menuButtonPressed: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${
        props.menuButtonPressed
          ? "mobileMenuContainer"
          : "mobileMenuContainerClosed"
      }`}
    >
      {props.menuButtonPressed
        ? props.pages.map((page) => {
            return (
              <>
                <a
                  key={`navbar2${page.link}`}
                  className="itemMobileMenu"
                  href={page.subPages ? R.string.routes.void : page.link}
                >
                  {t(page.name).toUpperCase()}
                </a>
                {page.subPages &&
                  page.subPages.map((sub) => (
                    <a
                      key={`a${page.link}`}
                      className="subItemMobileMenu"
                      href={sub.link}
                    >
                      {t(sub.name).toUpperCase()}
                    </a>
                  ))}
              </>
            );
          })
        : ""}
    </div>
  );
};

function Navbar(props: {}) {
  const { t } = useTranslation();

  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 960px)").matches
  );

  const [menuButtonPressed, setMenuButtonPressed] = useState(false);
  const menuPages = useMenuPages();
  const { pathname } = useLocation();

  useEffect(() => {
    window
      .matchMedia("(min-width: 960px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  return (
    <div>
      {
        //Mobile Menu Version
        !isDesktop ? (
          <MobileMenu menuButtonPressed={menuButtonPressed} pages={menuPages} />
        ) : (
          ""
        )
      }

      <div id="topAreaContainer">
        <div className="buttonsRow">
          <div className="ctaButtonContainer">
            <a
              href={R.string.routes.login}
              className="ctaButton"
              id="loginButton"
            >
              {t("menu.decoCspLogin")}
            </a>
            <LanguageSelector />
          </div>

          <div className="ctaButtonContainer">
            <a
              href={R.string.contacts.hotlineLink}
              className="ctaButton"
              id="hotlineButton"
            >
              {t("menu.hotlineEmergencyCall")}
            </a>
          </div>
        </div>
        <div className="logoRow">
          {
            //Mobile Menu Button
            !isDesktop ? (
              <div
                className={`menuButton ${
                  menuButtonPressed ? "menuButtonPressed" : ""
                }`}
                onClick={() => setMenuButtonPressed((prev) => !prev)}
              >
                <div /> <div /> <div />
              </div>
            ) : (
              ""
            )
          }
          <a href="/" className="logo">
            <img alt="Logo" src={"/Logo.svg"}></img>
          </a>
        </div>
      </div>
      {isLogged() && pathname !== R.string.routes.center ? (
        <a
          href={R.string.routes.center}
          className="goToDashboardRow subMenuItem"
        >
          {t("menu.goToGuestsPage")}
        </a>
      ) : (
        ""
      )}
      {isDesktop && !isDashboardPage(pathname) ? (
        <nav>
          <ul id="navbar">
            {menuPages.map((page, index) => (
              <>
                <li key={`j${page.link}`}>
                  <NavbarItem page={page} />
                </li>
                {index < menuPages.length - 1 && "•"}
              </>
            ))}
          </ul>
        </nav>
      ) : (
        ""
      )}
    </div>
  );
}

const liNavbarItem = (props: {
  page: IPage;
  index: number;
  menuPages: IPage[];
}) => {
  return (
    <>
      <li key={`j${props.page.link}`}>
        <NavbarItem page={props.page} />
      </li>
      {props.index < props.menuPages.length - 1 && "•"}
    </>
  );
};

export default Navbar;
