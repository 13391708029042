import { useTranslation } from "react-i18next";
import Line from "../../components/ui/line";
import BorderView from "../../components/views/BorderView";
import { VerticalView } from "../../components/views/ContentViews";
import ImageCover from "../../components/views/ImageCover";

const FTD = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"ACS"} src={"/covers/health_ftd.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("health.ftd.title")}
            <Line color="var(--blue4)" />
          </h1>
          <text>
            <h3>
              <i>
                <b>{t("health.ftd.are")}</b>
              </i>
              &nbsp; {t("health.ftd.youSureThat")}
            </h3>
            <h3>
              <i>
                <b>{t("health.ftd.callUs")}</b>
              </i>
              &nbsp; {t("health.ftd.andBook")}
            </h3>
            <h3>
              <i>
                <b>{t("health.ftd.dearOur")}</b>
              </i>
              &nbsp; {t("health.ftd.doctorWill")}
            </h3>
            <h3>
              <i>
                <b>{t("health.ftd.forSimple")}</b>
              </i>
              &nbsp; {t("health.ftd.itMightBe")}
            </h3>
          </text>
        </VerticalView>
      </BorderView>
    </div>
  );
};

export default FTD;
