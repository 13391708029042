import { useEffect, useState } from "react";
import { GridView } from "../components/views/GridView";
import { ImageCircle } from "../components/views/ImageCover";
import { getPartners } from "src/network/content/getPartners";
import { IPartner } from "src/network/models/partner";
import Spinner from "src/components/ui/spinner";

const Partners = () => {
  const [partners, setPartners] = useState<IPartner[]>();
  useEffect(() => {
    const getPartnersCallback = async () => {
      const newPartners = await getPartners();
      setPartners(newPartners);
    };
    getPartnersCallback();
  }, []);

  return (
    <div>
      <GridView partnersGrid>
        {partners ? (
          partners.map((partner: IPartner) => {
            return (
              <ImageCircle
                key={partner.id}
                link={partner.website}
                alt={partner.name}
                src={partner.image}
              ></ImageCircle>
            );
          })
        ) : (
          <Spinner />
        )}
      </GridView>
    </div>
  );
};

export default Partners;
