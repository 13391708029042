import "./GridView.css"

export const GridView = (props: { partnersGrid?: boolean, children: React.ReactNode, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <div className={props.partnersGrid ? "sixElementsGridContainer" : "gridContainer"} style={props.style}>
            {props.children}
        </div>
    );
}


// gridTemplateColumns: `repeat(${columns}, auto)`