import { useTranslation } from "react-i18next";
import Line from "../../components/ui/line";
import BorderView from "../../components/views/BorderView";
import { VerticalView } from "../../components/views/ContentViews";
import ImageCover from "../../components/views/ImageCover";

const DES = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ImageCover alt={"DES"} src={"/covers/health_des.jpg"} />
      <BorderView>
        <VerticalView>
          <h1>
            {t("health.des.title")}
            <Line color="var(--blue4)" />
          </h1>
          <h3>{t("health.des.description")}</h3>
        </VerticalView>
      </BorderView>
    </div>
  );
};

export default DES;
