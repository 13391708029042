import React, { useState } from 'react';
import { addGuest } from '../../network/guests/addGuest';
import "./addGuestPopup.styles.css";
import ShareLinkModal from './SharePopup';
import { IGuest } from '../../network/models/guest';

interface FormData {
  name: string;
  email: string;
  passport_id_number: string;
  birth_date: string;
  paid: boolean;
  phone: string;
}

const validateFields = (formData: FormData, setError: React.Dispatch<React.SetStateAction<string>>): boolean => {

  // Validate name field
  if (formData.name.trim() === "") {
    setError("Please enter a name");
    return false;
  }

  // Validate email field
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (formData.email.trim() !== "" && !emailRegex.test(formData.email)) {
    setError("Please enter a valid email address");
    return false;
  }

  // Validate passport ID field
  const passportRegex = /^[A-Z0-9]+$/;
  if (formData.passport_id_number.trim() !== "" && !passportRegex.test(formData.passport_id_number)) {
    setError("Please enter a valid passport ID");
    return false;
  }

  // Validate phone field
  const phoneRegex = /^\d{10,}$/;
  if (formData.phone.trim() !== "" && !phoneRegex.test(formData.phone)) {
    setError("Please enter a valid phone number");
    return false;
  }

  // Validate birth date field
  if (formData.birth_date.trim() === "") {
    setError("Please enter a birth date");
    return false;
  }
  return true;
}

const FormModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [error, setError] = useState('');
  const [guestCode, setGuestCode] = useState<string>('');
  const [guest, setGuest] = useState<IGuest>();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    passport_id_number: '',
    birth_date: '',
    paid: false,
    phone: '',
  });
  const userLink = `https://decoworld.org/g/${guestCode}`;


  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setError('');
    setShowModal(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validation = validateFields(formData, setError);
    if (!validation) {
      return;
    };
    setShowModal(false);
    addGuest(formData).then(
      (res) => {
        setGuest(res[0]);
        const code = res[0].code.toString();
        return code;
      }
    ).then((code) => {
      // shareLink(uuid);
      setGuestCode(code);
      setShowShare(true);

    }
    );//.then(() => window.location.reload());
  };

  return (
    <div>
      <img onClick={handleShowModal} src="/icons/add-user.png" className="addGuest" alt="add guest" />
      {showModal && (
        <div className="backgroundBlur">
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="titleContainer">
              <h1 className="title">ADD GUEST</h1>
              <img onClick={handleCloseModal} src="/icons/cancel.png" className="close" alt="edit info" />

            </div>
            <div className="fields">
              <div className="formRow">
                <label id="name" className="formLabel" htmlFor="name">
                  Name :
                </label>
                <input
                  autoFocus
                  type="text"
                  name="name"
                  className="formInput"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Email :
                </label>
                <input
                  className="formInput"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formRow">
                <label id="passport_id_number" className="formLabel" htmlFor="passport_id_number">
                  Passport ID :
                </label>
                <input
                  className="formInput"
                  type="text"
                  name="passport_id_number"
                  value={formData.passport_id_number}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formRow">
                <label id="phone" className="formLabel" htmlFor="phone">
                  Phone :
                </label>
                <input
                  className="formInput"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formRow">
                <label id="birth_date" className="formLabel" htmlFor="birth_date">
                  Birth Date :
                </label>
                <input
                  className="formInput"
                  type="date"
                  name="birth_date"
                  value={formData.birth_date}
                  onChange={handleInputChange}
                />
              </div>
              <label className="errorTextRow">
                {error}
              </label>
              <div className="formButtonsRow">
                <button type="submit">Submit</button>
                <button onClick={handleCloseModal}>Close</button>
              </div>

            </div>
          </form>
        </div >
      )}
      {
        showShare &&
        <ShareLinkModal
          guest={guest}
          showModal={showShare}
          link={userLink}
          onClose={() => {
            setShowShare(false);
            window.location.reload();
          }}
        />
      }
    </div >
  );
};

export default FormModal;
