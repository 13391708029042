import React from 'react';

const Spinner = (white: { white?: boolean; }) => {
  return (
    <div className="spinner-container">
      <img src="/icons/spinner-blue4.gif"  alt="Loading..." className="spinner" />
    </div>
  );
};

export default Spinner;