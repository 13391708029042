import { useState } from "react";
import "./form.styles.css";
import { isLogged, submitLogin } from "../../network/auth/login";
import { R } from "../../res";
import {
  removeTokens,
  setToken,
  setTokenID,
  setUUID,
  setUser,
} from "../../network/config/localStorage";
import { ICenter } from "../../network/models/user";

const LoginForm = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const handleSubmit = async () => {
    const res: ICenter = await submitLogin(username, password);
    if (res.user) {
      window.location.assign(R.string.routes.center);
      setToken(res.token);
      setTokenID(res.token_id);
      setUUID(res.user?.uuid);
      setUser(res);
    } else {
      alert(JSON.stringify(res));
    }
  };

  return (
    <div className="backgroundBlur">
    {
      !isLogged()
      ?
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="titleContainer">
          <h1 className="title">LOGIN</h1>
          <img onClick={() => window.location.assign(R.string.routes.home)} 
            src="/icons/cancel.png" className="close" alt="edit info" />

        </div>
        <div className="fields">
          <div className="formRow">
            <label id="emailLabel" className="formLabel" htmlFor="email">
              Username :
            </label>
            <input
              autoFocus
              type="text"
              className="formInput"
              name="email"
              id="email"
              value={username}
              onChange={(v) => setUsername(v.target.value)}
            />
          </div>
          <div className="formRow">
            <label id="passwordLabel" className="formLabel" htmlFor="password">
              Password :
            </label>
            <input
              type="password"
              className="formInput"
              name="password"
              id="password"
              value={password}
              onChange={(v) => setPassword(v.target.value)}
            />
          </div>
          <button onClick={handleSubmit} className="submitButton" type="button">
            Login
          </button>
        </div>
      </form>
    :
    (<div className="loggedInContainer">
      <h2>You are already logged in</h2>
      <img onClick={() => window.history.back()} src="/icons/cancel.png" className="close" alt="edit info" />
      <button className="outButton" onClick={() => {
        removeTokens();
        window.location.reload()
      }}>Logout</button>
      <button className="outButton" onClick={() => {
        window.location.assign(R.string.routes.center);
      }}>Go to Guests Page</button>
    </div>)
  }
  </div>
  );
};

export default LoginForm;
