import { useCallback, useEffect, useMemo, useState } from "react";
import "./center.styles.css";
import { Direction, getGuests } from "../../network/guests/getGuests";
import BorderView from "../../components/views/BorderView";
import { getUser, removeTokens } from "../../network/config/localStorage";
import { R } from "../../res";
import FormModal from "./addGuestPopup";
import { isLogged } from "../../network/auth/login";
import LoginForm from "../auth/login";
import { ICenter } from "../../network/models/user";
import { setMultipleGuestsPayment } from "../../network/guests/setMultipleGuestsPayment";
import BulkPopup from "./BulkPopup";
import Row from "./Row";
import { IGuest } from "../../network/models/guest";
import useDesktop from "../../hooks/useDesktop";
import GuestFilters from "./GuestFilters";
import GuestPagination from "./GuestPagination";
import SortableHeaderCell from "./SortableHeaderCell";

const Center = () => {
  const [guests, setGuests] = useState<IGuest[]>([]);
  const center: ICenter = getUser();
  const [logo, setLogo] = useState(center?.user?.image);
  const isDesktop = useDesktop();
  const [bulkActionGuests, setBulkActionGuests] = useState<IGuest[]>([]);
  const [showBulkPopup, setShowBulkPopup] = useState(false);

  // Filter, Sort and Pagination Hooks
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [sort, setSort] = useState("id");
  const [direction, setDirection] = useState(Direction.desc);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [passportIdNumber, setPassportIdNumber] = useState("");
  const [paid, setPaid] = useState<boolean | undefined>(undefined);
  const [totalPages, setTotalPages] = useState<number>(1); // Add totalPages state
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleLogout = useCallback(async () => {
    removeTokens();
    window.location.assign(R.string.routes.login);
  }, []);

  const toggleChecked = useCallback(
    (guest: IGuest) => {
      const guestIndex: number = bulkActionGuests.findIndex(
        (g) => g.uuid === guest.uuid
      );
      if (guestIndex === -1) {
        setBulkActionGuests((prev) => [...prev, guest]);
      } else {
        const newBulkActionGuests: IGuest[] = [];
        bulkActionGuests.forEach((g) => {
          if (guest.uuid !== g.uuid) {
            newBulkActionGuests.push(g);
          }
        });
        setBulkActionGuests(newBulkActionGuests);
      }
    },
    [bulkActionGuests]
  );

  const handleEditInfo = useCallback(async () => {
    window.location.assign(R.string.routes.editInfo);
  }, []);

  useEffect(() => {
    const callGetGuests = async () => {
      const response = await getGuests({
        page,
        limit,
        sort,
        direction,
        name,
        phone,
        passport_id_number: passportIdNumber,
        paid,startDate,endDate,
      });
      setGuests(response.data);
      // Set the total pages based on the data from the API response
      setTotalPages(Math.ceil(response.total / limit));
    };
    callGetGuests();
  }, [page, limit, sort, direction, name, phone, passportIdNumber, paid,startDate,endDate]);

  const [selectedAction, setSelectedAction] = useState(""); // State for the selected bulk action

  const bulkActions = [{ value: "setPaid", label: "Set as Paid" }];

  const handleBulkActionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedAction(event.target.value);
  };
  const handleExecuteConfirmationBulkAction = useCallback(async () => {
    if (selectedAction === "setPaid") {
      await setMultipleGuestsPayment(bulkActionGuests);

      const updatedGuests = guests.map((guest) => {
        if (bulkActionGuests.some((g) => g.uuid === guest.uuid)) {
          return {
            ...guest,
            paid: true,
          };
        }
        return guest;
      });

      setGuests([...updatedGuests]);
    }
  }, [bulkActionGuests, guests, selectedAction]);

  const handleExecuteBulkAction = () => {
    if (selectedAction === "setPaid") {
      setShowBulkPopup(true);
    }
  };

  const renderGuestsRows = useCallback(() => {
    return guests?.map((guest, index) => (
      <Row
        guest={guest}
        index={index}
        checked={bulkActionGuests.findIndex((g) => g.id === guest.id) !== -1}
        toggleChecked={toggleChecked}
      />
    ));
  }, [bulkActionGuests, guests, toggleChecked]);

  const filteredBulkGuests = useMemo(() => {
    if (selectedAction === "setPaid") {
      return bulkActionGuests.filter((guest) => !guest.paid);
    }
    return bulkActionGuests;
  }, [bulkActionGuests, selectedAction]);

  if (showBulkPopup && filteredBulkGuests.length > 0) {
    return (
      <BulkPopup
        showModal={showBulkPopup}
        onClose={() => setShowBulkPopup(false)}
        onConfirm={handleExecuteConfirmationBulkAction}
        guests={filteredBulkGuests}
        confirmButtonText={
          bulkActions.find((action) => action.value === selectedAction)?.label
        }
      />
    );
  }

  const handleFiltersChange = (key: string, value: any) => {
    console.log(key);
    switch (key) {
      case "":
        setName("");
        setPhone("");
        setPassportIdNumber("");
        setPage(1);
        break;
      case "name":
        setName(value);
        setPhone("");
        setPassportIdNumber("");
        setPage(1);
        break;
      case "phone":
        setName("");
        setPhone(value);
        setPassportIdNumber("");
        setPage(1);
        break;
      case "passportIdNumber":
        setName("");
        setPhone("");
        setPassportIdNumber(value);
        setPage(1);
        break;
      case "paid":
        setPaid(value);
        setPage(1); // Reset the page to 1 when filters change
        break;
        case "startDate":
          setStartDate(value);
          setPage(1); // Reset the page to 1 when filters change
          break;
          case "endDate":
            setEndDate(value);
            setPage(1); // Reset the page to 1 when filters change
            break;
      default:
        break;
    }
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (column: string) => {
    if (column === sort) {
      // If clicking the same column, toggle the sort direction
      setDirection(
        direction === Direction.asc ? Direction.desc : Direction.asc
      );
    } else {
      // If clicking a new column, default to ascending sort direction
      setSort(column);
      setDirection(Direction.asc);
    }
    // You can also reset the page here if needed
    setPage(1);
  };

  return (
    <>
      {isLogged() ? (
        <div className="container">
          <div className="headerRow">
            <div className="row">
              <img
                onClick={handleEditInfo}
                src="/icons/edit-info.png"
                className="logout"
                alt="edit info"
              />
              <img
                onClick={handleLogout}
                src="/icons/log-out.png"
                className="logout"
                alt="add guest"
              />
              {logo && (
                <img
                  className="logoImage"
                  src={logo}
                  alt="Logo"
                  onError={() => {
                    setLogo(undefined);
                  }}
                />
              )}
              {center?.user && (
                <BorderView>
                  <h2>{center?.user?.name}</h2>
                </BorderView>
              )}
              <FormModal></FormModal>
            </div>
          </div>
          <div
            style={{ overflowX: "auto", alignContent: "start", width: "100%" }}
          >
            <div className="bulkActions">
              <select value={selectedAction} onChange={handleBulkActionChange}>
                <option value="">Select Bulk Action</option>
                {bulkActions.map((action) => (
                  <option key={action.value} value={action.value}>
                    {action.label}
                  </option>
                ))}
              </select>
              <button onClick={handleExecuteBulkAction}>Execute</button>
              <GuestFilters onFiltersChange={handleFiltersChange} paid={paid} />
            </div>

            <table className="guestsTable tableStyle">
              {isDesktop && (
                // Inside your component's render function
                <tr>
                  <th className="">
                    <input
                      type="checkbox"
                      checked={bulkActionGuests.length > 0}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked) {
                          setBulkActionGuests(guests);
                        } else {
                          setBulkActionGuests([]);
                        }
                      }}
                    />
                  </th>
                  <SortableHeaderCell
                    label="NO"
                    fieldName="id"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color8"}
                  />
                  <SortableHeaderCell
                    label="GUEST NAME"
                    fieldName="name"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color7"}
                  />
                  <SortableHeaderCell
                    label="DATE OF BIRTH"
                    fieldName="birth_date"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color6"}
                  />
                  <SortableHeaderCell
                    label="REMAINING TIME"
                    fieldName="created"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color5"}
                  />
                  <SortableHeaderCell
                    label="REGISTRATION DATE"
                    fieldName="created"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color4"}
                  />
                  <SortableHeaderCell
                    label="EXPIRATION DATE"
                    fieldName="created"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color3"}
                  />
                  <SortableHeaderCell
                    label="PAID"
                    fieldName="paid"
                    currentSort={sort}
                    sortDirection={direction}
                    onSortClick={handleSort}
                    className={"color2"}
                  />
                  <th className="color1">SHARE</th>
                </tr>
              )}
              {renderGuestsRows()}
            </table>
            <GuestPagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="container">
          <LoginForm></LoginForm>
        </div>
      )}
    </>
  );
};

export default Center;
