import { getUrl } from "../config/url";
import { setGuestPaymentEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";
import { IGuest, INewGuest } from "../models/guest";


export const setGuestPayment = async (guest: IGuest, pay: boolean ): Promise<INewGuest> => {

  var myHeaders = new Headers();
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var urlencoded = new URLSearchParams();
  urlencoded.append("paid", pay.toString());
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };
    //to avoid cache issue I added timeStamp 
    const response = await fetch(`${getUrl(setGuestPaymentEndPoint)}/${guest.uuid}?timeStamp=${ new Date().getTime().toString()}`, requestOptions)
    const obj = await response.json();
    const data: IGuest[] = await obj.data;

  return data[0];

};
