import { getUrl } from "../config/url";
import { getGuestsEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";
import { IGuest } from "../models/guest";

export enum Direction {
  desc = "desc",
  asc = "asc",
}
export const getGuests = async ({
  page = 1,
  limit = 1000,
  sort = "id",
  direction = Direction.desc,
  name = "",
  phone,
  passport_id_number,
  paid,startDate,endDate,
}: {
  page?: number;
  limit?: number;
  sort?: string;
  direction?: Direction;
  name?: string;
  phone?: string;
  passport_id_number?: string;
  paid?: boolean;
  startDate?: string;
  endDate?: string;
} = {}): Promise<{data: IGuest[], total: number}> => {
  var myHeaders = new Headers();
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  const queryParams = new URLSearchParams({
    page: String(page),
    limit: String(limit),
    sort: sort,
    direction: direction,
    name: name,
  });
  if (!!phone) {
    queryParams.append('phone', phone)
  }
  if (!!passport_id_number) {
    queryParams.append('passport_id_number', passport_id_number)
  }
  if (paid !== undefined) {
    queryParams.append('paid', paid ? '1' : '0')
  }
  if (startDate !== undefined && startDate!=='') {
    queryParams.append('startDate', startDate)
  }
  if (endDate !== undefined && endDate!=='') {
    queryParams.append('endDate', endDate)
  }
//to avoid cache issue
queryParams.append('timeStamp', new Date().getTime().toString()) 
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  const url = getUrl(getGuestsEndPoint) + "?" + queryParams.toString();
  console.log(url);

  const response = await fetch(url, requestOptions);
  const obj = await response.json();
  console.log('obj:', obj)
  const data: IGuest[] = obj.data;

  return obj;
};
