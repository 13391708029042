import { getUrl } from "../config/url";
import { getPartnersEndPoint } from "../config/endpoints";
import { IPartner } from "../models/partner";

export const getPartners = async (): Promise<IPartner[]> => {
  var requestOptions = {
    method: "GET",
  };

  const response = await fetch(getUrl(getPartnersEndPoint), requestOptions);
  const obj = await response.json();
  const data: IPartner[] = await obj.data;

  return data;
};
