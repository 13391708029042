import React, { ChangeEvent, FC, useState } from "react";

interface GuestFiltersProps {
  onFiltersChange: (key: string, value: any) => void;
  paid: boolean | undefined;
}
const GuestFilters: FC<GuestFiltersProps> = ({
  onFiltersChange,
  paid,
}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputStartDateValue, setInputStartDateValue] = useState("");
  const [inputEndDateValue, setInputEndDateValue] = useState("");
  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputValue("");
    onFiltersChange(event.target.value, "");
    setSelectedFilter(event.target.value);
    onFiltersChange(event.target.value, "");
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onFiltersChange(selectedFilter, event.target.value);
  };
  const handleInputStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputStartDateValue(event.target.value);
    onFiltersChange("startDate", event.target.value);
  };
  const handleInputEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputEndDateValue(event.target.value);
    onFiltersChange("endDate", event.target.value);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        marginLeft: 20,
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <label>Filter by:</label>
        <select value={selectedFilter} onChange={handleFilterChange}>
          <option value="">Select an option</option>
          <option value="name">Name</option>
          <option value="phone">Phone</option>
          <option value="passportIdNumber">Passport ID Number</option>
        </select>
        {selectedFilter && (
          <>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
            />
          </>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <label>Paid:</label>
        <select
          value={paid === undefined ? "" : paid.toString()}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            onFiltersChange(
              "paid",
              e.target.value === "true"
                ? true
                : e.target.value === "false"
                ? false
                : undefined
            )
          }
        >
          <option value="">All</option>
          <option value="true">Paid</option>
          <option value="false">Not Paid</option>
        </select>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <label>From:</label> 
            <input
              type="date"
              value={inputStartDateValue}
              onChange={handleInputStartDateChange}
            /> 
             <label>To:</label> 
            <input
              type="date"
              value={inputEndDateValue}
              onChange={handleInputEndDateChange}
            /> 
      </div>
      
    </div>
  );
};

export default GuestFilters;
