import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getLanguage, setLanguage } from "src/network/config/localStorage";

interface LanguageSelectorProps {}

const LanguageSelector: React.FC<LanguageSelectorProps> = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    getLanguage() ?? "en"
  );
  const [isSelectingLanguage, setIsSelectingLanguage] = useState(false);

  const { i18n } = useTranslation();

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const toggleLanguageSelection = () => {
    setIsSelectingLanguage(!isSelectingLanguage);
  };

  return (
    <div style={{ margin: 5 }}>
      <div
        id="languageSelect"
        className={`language-select ${isSelectingLanguage ? "open" : ""}`}
        onClick={toggleLanguageSelection}
      >
        {isSelectingLanguage ? (
          <>
            <img
              onClick={() => handleLanguageChange("en")}
              className="language-option"
              src="/icons/lang/en.png"
              alt="En"
            />

            <img
              onClick={() => handleLanguageChange("ger")}
              className="language-option"
              src="/icons/lang/ger.png"
              alt="De"
            />
          </>
        ) : (
          <img
            className="language-option"
            src={`/icons/lang/${selectedLanguage}.png`}
            alt={selectedLanguage.toUpperCase()}
          />
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
