import { PropsWithChildren } from "react";
import "./BorderView.css"

const BorderView = (props: PropsWithChildren): React.ReactElement => {
    return (
        <div id="borderViewContainer">
            <div className="borderViewLine" id="borderViewLine1">
                <div className="borderViewLine" id="borderViewLine2">
                    <div className="borderViewLine" id="borderViewLine3">
                        <div id="borderTextContainer">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BorderView;