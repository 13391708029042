import { useEffect, useState } from "react";

const useDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 960px)").matches);
  
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setIsDesktop(e.matches));
  }, []);

  return isDesktop;
}

export default useDesktop;