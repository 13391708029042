import React, { FC } from 'react';
import "./center.styles.css";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const GuestPagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };
  if (totalPages < 2) {
  return null
}
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 10 }}>
      <div>
        <button style={{margin: 10}} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          {'<'}
        </button>
        <span>  {currentPage}</span>
        <span> / </span>
        <span>{totalPages}  </span>
        <button style={{margin: 10}} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        {'>'}
        </button>
      </div>
    </div>
  );
};

export default GuestPagination;
