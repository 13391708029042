import { getUrl } from "../config/url";
import { changePasswordEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";

export const changePassword = async (password?: string) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);

  var urlencoded = new URLSearchParams();

  if(password){
      urlencoded.append("password", password);
  }

  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };
  //to avoid cache issue I added timeStamp 
  const response = await fetch(getUrl(changePasswordEndPoint)+`?timeStamp=${ new Date().getTime().toString()}`, requestOptions);

  console.log(response);
};
