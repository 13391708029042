import { FC } from "react";
import { Direction } from "src/network/guests/getGuests";

interface SortableHeaderCellProps {
  label: string;
  fieldName: string;
  currentSort: string;
  sortDirection: Direction;
  onSortClick: (fieldName: string) => void;
  className?: string;
}

const SortableHeaderCell: FC<SortableHeaderCellProps> = ({
  label,
  fieldName,
  currentSort,
  sortDirection,
  onSortClick,
  className='color4',
}) => (
  <th
    className={`sortable  ${className} ${currentSort === fieldName ? "active" : ""}`}
    onClick={() => onSortClick(fieldName)}
  >
    {label}
    {currentSort === fieldName && (
      <span className={sortDirection === Direction.asc ? "asc" : "desc"}></span>
    )}
  </th>
);

export default SortableHeaderCell;