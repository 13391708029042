import { useCallback, useState } from "react";
import "./form.styles.css";
import { isLogged } from "../../network/auth/login";
import { R } from "../../res";
import { getUser, setUser } from "../../network/config/localStorage";
import { ICenter, IUser } from "../../network/models/user";
import { editInfo } from "../../network/auth/editInfo";
import PasswordModal from "../dashboard/PasswordPopup";

const validateFields = (formData: ICenter, setError: React.Dispatch<React.SetStateAction<string>>): boolean => {

  // Validate name field
  if (!formData || !formData.user) {
    setError("Something is wrong");
    return false;
  }
  const user = formData.user;
  if (user?.name?.trim() === "") {
    setError("Please enter a name");
    return false;
  }

  // Validate email field
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (user?.email?.trim() !== "" && !emailRegex.test(user?.email ?? '')) {
    setError("Please enter a valid email address");
    return false;
  }

  // Validate phone field
  const phoneRegex = /^\d{10,}$/;
  if (user?.mobile?.trim() !== "" && !phoneRegex.test(user?.mobile ?? '')) {
    setError("Please enter a valid mobile number");
    return false;
  }

  return true;
}
const EditInfoForm = () => {
  const [center, setCenter] = useState<ICenter>(getUser());
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [error, setError] = useState('');
  const user = center.user;

  const handleSubmit = async () => {
    setError('');
    const validation = validateFields(center, setError);
    if (!validation) {
      return;
    };
    const res: IUser = await editInfo(center?.user);
    if (res) {
      window.location.assign(R.string.routes.center);
      alert('Center info updated successfully');
      setUser(center);
    } else {
      setError('Error updating info');
    }
  };

  const setUserValue = useCallback(
    (key: PropertyKey, value: string) => {
      const updatedCenter = { ...center };
      updatedCenter.user = {
        ...updatedCenter.user,
        [key]: value,
      };
      setCenter(updatedCenter);
    },
    [center],
  )

  return (
    <div className="backgroundBlur">
      {
        isLogged()
        &&
        (
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="titleContainer">
              <h1 className="title">EDIT INFO</h1>
              <img onClick={() => window.location.assign(R.string.routes.center)} src="/icons/cancel.png" className="close" alt="edit info" />
            </div>

            <div className="fields">
              <div className="formRow">
                <label id="passwordLabel" className="formLabel" htmlFor="password">
                  Name :
                </label>
                <input
                  autoFocus
                  type="text"
                  className="formInput"
                  name="name"
                  id="name"
                  value={user?.name}
                  onChange={(v) => setUserValue('name', v.target.value)}
                />
              </div>
              <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Email :
                </label>
                <input
                  type="text"
                  className="formInput"
                  name="email"
                  id="email"
                  value={user?.email}
                  onChange={(v) => setUserValue('email', v.target.value)}
                />
              </div>
              <div className="formButtonsRow">
                <label id="password" className="formFieldText" htmlFor="email">
                  Change Password :
                </label>
                <button onClick={() => {
                  setShowPasswordModal(true)
                }} type="button">
                  Change
                </button>
              </div>

              <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Website :
                </label>
                <input
                  type="text"
                  className="formInput"
                  name="website"
                  id="website"
                  value={user?.website}
                  onChange={(v) => setUserValue('website', v.target.value)}
                />
              </div>
              <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Map :
                </label>
                <input
                  type="text"
                  className="formInput"
                  name="map"
                  id="map"
                  value={user?.map}
                  onChange={(v) => setUserValue('map', v.target.value)}
                />
              </div>
              <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Manager :
                </label>
                <input
                  type="text"
                  className="formInput"
                  name="manager"
                  id="manager"
                  value={user?.manager}
                  onChange={(v) => setUserValue('manager', v.target.value)}
                />
              </div>
              <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Mobile :
                </label>
                <input
                  type="text"
                  className="formInput"
                  name="mobile"
                  id="mobile"
                  value={user?.mobile}
                  onChange={(v) => setUserValue('mobile', v.target.value)}
                />
              </div>
              {/* <div className="formRow">
                <label id="emailLabel" className="formLabel" htmlFor="email">
                  Image :
                </label>
                <input
                  type="image"
                  className="formInput"
                  name="image"
                  id="image"
                  value={user?.image}
                  onChange={(v) => setUserValue('image', v.target.value)}
                />
              </div> */}
              <label className="errorTextRow">
                {error}
              </label>
              <button onClick={handleSubmit} className="submitButton" type="button">
                Update
              </button>
            </div>
          </form>
        )
      }
      {
        showPasswordModal &&
        <PasswordModal
          showModal={showPasswordModal}
          onClose={() => {
            setShowPasswordModal(false);
            window.location.reload();
          }}
        />
      }
    </div>
  );
};

export default EditInfoForm;
