
const Locations = () => {
	return (
		// <div>
		// 	<iframe src=" https://www.google.com/maps/d/u/0/embed?mid=1gx-906pN_0or8j7-dmR8dMNDhy6SsqU" style={{ position: "relative", top: "-30px", border: "none" }} width="900" height="480"></iframe>
		// </div >
		<div id="locations_div">
			<iframe
				title="Map"
				src="https://www.google.com/maps/d/embed?mid=1y5y0ini0D--qcgVtS_uZXqF-y9IG5d8&ehbc=2E312F"
				width="100%"
				height="700px">

			</iframe>

		</div>
	);
};

export default Locations;
