import { getUrl } from "../config/url";
import { addGuestEndPoint } from "../config/endpoints";
import { getToken, getTokenID, getUUID } from "../config/localStorage";
import { IGuest, INewGuest } from "../models/guest";

export const addGuest = async (guest: INewGuest):Promise<IGuest[]> => {

  var myHeaders = new Headers();
  myHeaders.append("uuid", getUUID());
  myHeaders.append("token-id", getTokenID());
  myHeaders.append("Authorization", `Bearer ${getToken()}`);
  
  var urlencoded = new URLSearchParams();
  urlencoded.append("phone", guest.phone??'');
  urlencoded.append("name", guest.name);
  urlencoded.append("email", guest.email);
  urlencoded.append("passport_id_number", guest.passport_id_number ?? '');
  urlencoded.append("birth_date", guest.birth_date);
  urlencoded.append("paid", guest.paid.toString());

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };

  const response = await fetch(getUrl(addGuestEndPoint), requestOptions)

  const obj = await response.json();
  const data: IGuest[] = await obj.data;

  return data;

};
