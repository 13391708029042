import { link } from "fs";
import "./ImageCover.css"


const ImageCover = (props: { alt: string, src: string }): React.ReactElement => {
    return (
        <div id="ImageCoverContainer">
            <img width="960px" height="480px" id="ImageCover" src={props.src} alt={props.alt} />
        </div>
    );

}


export const ImageGrid = (props: { alt: string, src: string }): React.ReactElement => {
    return (
        <div>
            <img width="300px" className="imageMedia" src={props.src} alt={props.alt} />
        </div>
    );

}

export const ImageCircle = (props: { alt: string, src: string, link: string, style?: React.CSSProperties }): React.ReactElement => {
    return (
        <a href={props.link}>
            <img width="120px" height="120px" className="imageCircle" src={props.src} alt={props.alt} style={props.style} />
        </a>
    );

}


export default ImageCover;